<template>
  <div class="px-4 sm:px-6 lg:px-8 w-full">
    <NotificationPopup :type="notificationType" :title="notificationTitle" :text="notificationText" ref="notification" />

    <!-- Confirm Archive Offering -->
    <ConfirmArchiveOffering v-model="openArchiveOffering" @confirmed="archiveOffering" />

    <!-- Confirm Leaving Unsaved Changes -->
    <UnsavedChanges v-model="unsavedChanges" @confirmed="confirmedExit" />

    <Popup v-if="showOfferingPreview" v-model="showOfferingPreview" size="lg" title="Offering Preview" @close="showOfferingPreview = false" class="w-full h-full">
      <DealRoom :offering="o" />
    </Popup>

    <!-- Loading -->
    <div v-if="loading" class="flex flex-grow items-center justify-center h-[70vh]">
      <div class="w-32 h-32 -mt-10">
        <SpinnerJump />
      </div>
    </div>

    <!-- Offering Edit/Create -->
    <div v-else>

      <!-- Header -->
      <div>
        <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
          <!-- Tab Header -->
          <div class="flex gap-5 items-center">
            <!-- Back Button -->
            <div @click="handleBackButton" class="button_secondary w-fit h-fit">
              <ChevronLeftIcon class="w-4 h-4 text-black dark:text-white -mx-1" />
              Back
            </div>

            <h3 v-if="mode == 'create'" class="primary_header">Create Offering</h3>
            <h3 v-else class="primary_header">Edit Offering</h3>
          </div>


          <div v-if="mode == 'edit'" class="flex items-center gap-5">
            <div v-if="o.visibility == 'public' && o?.id" @click="ref_copy_offering_link.copy()" class="button_secondary">
              Copy Public Link
              <Copy :text="copiable_link" ref="ref_copy_offering_link" />
            </div>

            <ButtonSecondary text="See Full Preview" :icon="ArrowsPointingOutIcon" size="lg" @click="showOfferingPreview = true" />
          </div>

        </div>
      </div>

      <!-- Content -->
      <div class="relative flex flex-wrap justify-start gap-8 w-full">

        <!-- Offering Form -->
        <form @submit.prevent="handleSubmit()" @keydown="preventEnterKey" class="flex-1 flex-grow" ref="safe_offering_form">
          <div class="w-full">
            <div class="flex flex-col gap-x-6 gap-y-8 w-full mt-2">

              <!-- Top Section -->
              <div class="flex gap-16">
                <!-- Preview Card -->
                <div class="flex flex-col gap-8">
                  <div>
                    <p class="mb-4 tertiary_header">Offering card preview</p>
                    <div class="shadow-opera dark:shadow-none h-fit w-80 box-border flex justify-between flex-col background_secondary rounded-lg dark:text-neutral-300">

                      <!-- Card Header Logo -->
                      <img v-if="o.bannerUrl" class="flex items-center object-cover h-20 gap-2 text-lg bg-gray-200 dark:bg-neutral-700 rounded-t-lg sentry-unmask" :src="o.bannerUrl" />
                      <div v-else class="flex items-center h-20 gap-2 text-lg bg-gray-200 dark:bg-neutral-600 rounded-t-lg sentry-unmask"></div>

                      <div class="mx-5 -mt-5 overflow-hidden bg-white rounded-full ring-1 ring-border dark:ring-neutral-950 dark:bg-neutral-950 w-fit">
                        <img v-if="o.logoUrl" :src="o.logoUrl" class="w-10 h-10 object-fit sentry-unmask" />
                        <div v-else class="w-10 h-10 bg-gray-300 dark:bg-neutral-700 sentry-unmask"></div>
                      </div>

                      <!-- Card Name & Slogan -->
                      <div class="h-[160px]">
                        <div class="px-5 py-4 overflow-hidden font-semibold max-h-[80px sentry-unmask]">{{ o.name }}</div>
                        <div class="pb-2 px-5 max-h-[80px] text-sm grow">
                          <div class="sentry-unmask">{{ truncateText(o.slogan) }}</div>
                        </div>
                      </div>

                      <!-- Card Info -->
                      <div class="flex flex-col px-5 py-2 border-t divide-y divide-dashed divide-border border-border dark:border-neutral-600 dark:divide-neutral-600 sentry-unmask">
                        <div class="flex justify-between py-4 text-sm"><span>Market Sector</span><span>{{ o.market_sector }}</span></div>
                        <div v-if="partnerStore.partner.partners_protected.show_scores && o.show_score && o.score" class="flex justify-between py-4 text-sm"><span>Opera Score</span>

                          <div v-if="o.score && o.score != 'Not yet available'" class="flex items-center gap-1">
                            <span class="mr-1 text-xs text-gray-400">({{ o.score }})</span>
                            <OperaScore :score="o.score" />
                          </div>
                          <span v-else class="text-operacolor">{{ o.score }}</span>

                        </div>
                      </div>
                      <!-- Button -->
                      <div class="flex px-5 pb-5">
                        <div
                          class="justify-center w-full cursor-pointer inline-flex items-center gap-x-1.5 rounded-md font-medium bg-white dark:bg-operacolor dark:hover:bg-neutral-700 dark:hover:text-white dark:text-white px-2.5 py-1.5 text-sm text-operacolor shadow-sm ring-1 ring-inset ring-operacolor dark:ring-0 hover:bg-operacolor hover:text-white">
                          Learn More
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Section One (Card Info) -->
                <div class="flex flex-col gap-x-6 gap-y-8">

                  <!-- Super Admin Select Partner  -->
                  <div v-if="userStore.user.profiles_protected.role == 'super_admin' && mode == 'create'" class="flex flex-wrap w-full gap-10">
                    <div class="min-w-52 max-w-72">
                      <SafeSelect v-model="selectedPartner" :items="partners" label="Associated Partner" ref="safe_select_partner" type="partner" />
                    </div>
                  </div>

                  <!-- Row 1 -->
                  <div class="flex flex-wrap w-full gap-10">
                    <!-- Name -->
                    <SafeInput v-model="o.name" id="name" label="Offering Name" class="flex-1 min-w-52" ref="safe_name" />

                    <!-- Status -->
                    <SafeSelect v-model="o.status" label="Status" :items="['approved', 'pending', 'disapproved']" class="min-w-52 max-w-72" ref="safe_select_status" />

                    <!-- Offering Type -->
                    <SafeSelect v-model="o.offering_type" :items="['equity', 'debt', 'hybrid', 'fund']" label="Offering Type" class="min-w-52 max-w-72" ref="safe_select_offering_type" />
                  </div>

                  <!-- Slogan -->
                  <SafeTextarea v-model="o.slogan" label="Slogan" id="slogan" ref="safe_slogan" />

                  <!-- Row 3 -->
                  <div class="flex flex-wrap gap-10">
                    <!-- Logo Image -->
                    <div>
                      <div class="!flex items-center gap-2 input_label">
                        Logo Image
                        <Tooltip content="For best results use a square image, height and width as close to the same as possible">
                          <QuestionMarkCircleIcon class="size-4 text-gray-600 cursor-pointer dark:text-neutral-400" />
                        </Tooltip>
                      </div>
                      <div class="flex items-center mt-2 gap-x-3">
                        <PhotoIcon v-if="!o.logoUrl" class="w-12 h-12 text-gray-300" aria-hidden="true" />
                        <img v-else :src="o.logoUrl" alt="Logo Image" class="w-12 h-12 rounded-full object-fit">
                        <label for="circle-image-upload-edit" type="button" class="button_secondary">Change</label>
                        <input id="circle-image-upload-edit" name="circle-image-upload-edit" type="file" class="sr-only" accept="image/*" @change="logoImageChanged" />
                      </div>
                    </div>

                    <!-- Banner Image -->
                    <div>
                      <div class="!flex items-center gap-2 input_label">
                        Banner Image
                        <Tooltip content="The smaller this file is the faster it will load when displayed to investors, we recommend a file size of 1 MB or less.">
                          <QuestionMarkCircleIcon class="size-4 text-gray-600 cursor-pointer dark:text-neutral-400" />
                        </Tooltip>
                      </div>
                      <div class="flex items-center mt-2 gap-x-3">
                        <PhotoIcon v-if="!o.bannerUrl" class="w-12 h-12 text-gray-300" aria-hidden="true" />
                        <img v-else :src="o.bannerUrl" alt="Banner Image" class="w-24 h-12 rounded-lg object-fit">
                        <label for="banner-image-upload-edit" type="button" class="button_secondary">Change</label>
                        <input id="banner-image-upload-edit" name="banner-image-upload-edit" type="file" class="sr-only" accept="image/*" @change="bannerImageChanged" />
                      </div>
                    </div>

                    <!-- Market Sector -->
                    <SafeInput v-model="o.market_sector" id="sector" label="Market Sector" class="flex-1 min-w-52" ref="safe_market_sector" />

                    <!-- Opera Score -->
                    <SafeInput v-if="userStore.user.profiles_protected.role == 'super-admin' || partnerStore.partner.partner_scores" v-model="o.score" id="score" label="Opera Score" placeholder="0-5"
                      tooltip="Use an integer 1 through 5, or use the key phrase 'Not yet available'" class="flex-1 min-w-52 max-w-72" ref="safe_score" />
                  </div>

                </div>
              </div>

              <!-- Offering Content Options -->
              <div>
                <h2 class="text-base font-semibold text-gray-900 dark:text-neutral-100">Offering Content</h2>
                <p class="mt-1 text-sm text-gray-500 dark:text-neutral-500">Customize your offerings content to display the most important information to your investors.</p>
                <ul role="list" class="mt-6 grid grid-cols-1 gap-6 border-b border-t border-gray-200 dark:border-neutral-700 py-6 sm:grid-cols-3">
                  <li v-for="(item, itemIdx) in contentOptions" :key="itemIdx" class="flow-root">
                    <div class="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-operacolor hover:bg-gray-100 dark:hover:bg-neutral-800 cursor-pointer">
                      <div :class="[item.background, 'flex size-16 shrink-0 items-center justify-center rounded-lg self-start']">
                        <component :is="item.icon" class="size-6 text-white" aria-hidden="true" />
                      </div>
                      <div>
                        <h3 class="text-sm font-medium text-gray-900 dark:text-neutral-100">
                          <div @click="handleOptionClick(item.id)" class="focus:outline-none">
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            <span>{{ item.title }}</span>
                            <span aria-hidden="true"> &rarr;</span>
                          </div>
                        </h3>
                        <p class="mt-1 text-sm text-gray-500 dark:text-neutral-500">{{ item.description }}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <!-- Settings Options -->
              <div>
                <h2 class="text-base font-semibold text-gray-900 dark:text-neutral-100">Settings</h2>
                <p class="mt-1 text-sm text-gray-500 dark:text-neutral-500">Customize your offering's settings to reflect your legal requirments and expectations for investors.</p>
                <ul role="list" class="mt-6 grid grid-cols-1 gap-6 border-b border-t border-gray-200 dark:border-neutral-700 py-6 sm:grid-cols-3">
                  <li v-for="(item, itemIdx) in settingsOptions" :key="itemIdx" class="flow-root">
                    <div class="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-operacolor hover:bg-gray-100 dark:hover:bg-neutral-800 cursor-pointer">
                      <div :class="[item.background, 'flex size-16 shrink-0 items-center justify-center rounded-lg self-start']">
                        <component :is="item.icon" class="size-6 text-white" aria-hidden="true" />
                      </div>
                      <div>
                        <h3 class="text-sm font-medium text-gray-900 dark:text-neutral-100">
                          <div class="focus:outline-none" @click="handleOptionClick(item.id)">
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            <span>{{ item.title }}</span>
                            <span aria-hidden="true"> &rarr;</span>
                          </div>
                        </h3>
                        <p class="mt-1 text-sm text-gray-500 dark:text-neutral-500">{{ item.description }}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <!-- Overview & Description -->
              <OfferingOverview v-model="o" ref="safe_slider_overview" />

              <!-- Paragraphs and Images -->
              <OfferingContent v-model="o" />

              <!-- Links -->
              <OfferingLinks v-model="o" />

              <!-- Documents -->
              <OfferingDocuments v-model="o" />

              <!-- Content Order -->
              <OfferingOrder v-model="o" />

              <!-- Checkout Customization -->
              <OfferingCheckout v-model="o" />

              <!-- Forum Manage -->
              <OfferingForum v-model="o" />

              <!-- Visibility -->
              <OfferingVisibility v-model="o" />

              <!-- Scores -->
              <OfferingScores v-model="o" />

              <!-- Settings -->
              <OfferingSettings v-model="o" />

              <!-- TAPI Settings -->
              <OfferingTapi v-model="o" />

              <!-- Payment Settings -->
              <OfferingPayments v-model="o" />

            </div>
          </div>

          <div class="flex items-center justify-end py-4 gap-x-6 mt-4 flex-grow">
            <ButtonPrimary text="Archive" color="red" v-if="mode == 'edit'" @click="openArchiveOffering = true" class="mr-auto" size="lg" />
            <ButtonSecondary text="Cancel" @click="handleBackButton" size="lg" />
            <ButtonPrimary text="Save & Publish" type="submit" size="lg" :loading="isPublishing" />
          </div>

        </form>
      </div>

    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, watch, onMounted, nextTick } from 'vue'

// Schemas
import { OfferingSchema } from '@/schemas/OfferingSchema.ts'

// Router
import { useRouter } from 'vue-router'
const router = useRouter()
import { useRoute } from 'vue-router';
const route = useRoute();

// Stores
import { useUserStore } from '@/stores/user';
const userStore = useUserStore();
import { usePartnerStore } from '@/stores/partner';
const partnerStore = usePartnerStore();
import { useOfferingsStore } from '@/stores/offerings';
const offeringsStore = useOfferingsStore();
import { useEmailsStore } from '@/stores/emails';
const emailsStore = useEmailsStore();

// Components
import DealRoom from '@/views/dashboard/investor/deals/DealRoom.vue'
import NotificationPopup from '@/components/popups/NotificationPopup.vue'
import SpinnerJump from '@/components/loading/SpinnerJump.vue'
import ButtonPrimary from '@/components/applicationui/ButtonPrimary.vue';
import ButtonSecondary from '@/components/applicationui/ButtonSecondary.vue';
import OperaScore from '@/components/ui/OperaScore.vue'
import Tooltip from '@/components/applicationui/Tooltip.vue';
import UnsavedChanges from '@/components/popups/UnsavedChanges.vue';
import ConfirmArchiveOffering from '@/components/popups/ConfirmArchiveOffering.vue';
import Popup from '@/components/applicationui/Popup.vue';
import Copy from '@/components/applicationui/Copy.vue';
import SafeSelect from '@/components/inputs/safeSelect.vue';
import SafeInput from '@/components/inputs/safeInput.vue';
import SafeTextarea from '@/components/inputs/safeTextarea.vue';

// offering slideover components
import OfferingOverview from '@/components/slideovers/offering/OfferingOverview.vue'
import OfferingContent from '@/components/slideovers/offering/OfferingContent.vue'
import OfferingLinks from '@/components/slideovers/offering/OfferingLinks.vue'
import OfferingDocuments from '@/components/slideovers/offering/OfferingDocuments.vue'
import OfferingOrder from '@/components/slideovers/offering/OfferingOrder.vue'
import OfferingCheckout from '@/components/slideovers/offering/OfferingCheckout.vue'
import OfferingVisibility from '@/components/slideovers/offering/OfferingVisibility.vue'
import OfferingScores from '@/components/slideovers/offering/OfferingScores.vue'
import OfferingSettings from '@/components/slideovers/offering/OfferingSettings.vue'
import OfferingTapi from '@/components/slideovers/offering/OfferingTapi.vue'
import OfferingPayments from '@/components/slideovers/offering/OfferingPayments.vue'
import OfferingForum from '@/components/slideovers/offering/OfferingForum.vue';

// Libraries
import { truncateText } from '@/helper/helper'
import { PhotoIcon, ChevronLeftIcon, ListBulletIcon, StarIcon } from '@heroicons/vue/24/solid'
import {
  LinkIcon, EyeIcon, QuestionMarkCircleIcon,
  DocumentPlusIcon, EyeDropperIcon, ArrowsPointingOutIcon, CreditCardIcon, KeyIcon,
  ArrowPathRoundedSquareIcon, ExclamationTriangleIcon, Cog8ToothIcon, ChatBubbleBottomCenterTextIcon
} from '@heroicons/vue/24/outline'

// HTML Only Refs START
const ref_copy_offering_link = ref(null);
// HTML Only Refs END

// Offering Tabs START
const settingsOptions = ref([
  {
    id: 'settings',
    title: 'Settings',
    description: 'Customize the most important settings for your offering.',
    icon: Cog8ToothIcon,
    background: 'bg-slate-500',
    required: true,
  },
  {
    id: 'payment',
    title: 'Payment Settings',
    description: 'Customize accepted payment methods and instructions for your offering.',
    icon: CreditCardIcon,
    background: 'bg-violet-500',
    required: true,
  },
  {
    id: 'tapi',
    title: 'Transact API (Advanced)',
    description: 'Customize transact API settings. These settings may have unintended repercussions use caution.',
    icon: ExclamationTriangleIcon,
    background: 'bg-orange-500',
    required: false,
  }
])

const contentOptions = ref([
  // Row 1
  {
    id: 'overview',
    title: 'Overview & Description',
    description: "A high-level overview and description of your offering with key info and dynamic data.",
    icon: KeyIcon,
    background: 'bg-rose-500',
    required: false,
  },
  {
    id: 'paragraphs',
    title: 'Paragraphs',
    description: 'Add and edit paragraphs of text to your offering. Use this to provide more detailed information.',
    icon: ListBulletIcon,
    background: 'bg-purple-500',
    required: false,
  },
  {
    id: 'images',
    title: 'Images and Charts',
    description: 'Add and edit images and charts to your offering. Use this to provide visual information.',
    icon: PhotoIcon,
    background: 'bg-sky-500',
    required: false,
  },

  // Row 2
  {
    id: 'links',
    title: 'Links',
    description: "Add and edit links to your offering's website, social media, and other resources.",
    icon: LinkIcon,
    background: 'bg-blue-500',
    required: false,
  },
  {
    id: 'documents',
    title: 'Offering Documents',
    description: 'Add and edit offering documents with legal information or investing details.',
    icon: DocumentPlusIcon,
    background: 'bg-pink-500',
    required: false
  },
  {
    id: 'order',
    title: 'Order your Content',
    description: 'Drag and drop to reorder your content sections. Make your most important content pop.',
    icon: ArrowPathRoundedSquareIcon,
    background: 'bg-emerald-500',
    required: false,
  },

  // Row 3
  {
    id: 'checkout',
    title: 'Customize Checkout',
    description: 'Customize your checkout experience. Provide a unique checkout experience for your investors.',
    icon: EyeDropperIcon,
    background: 'bg-teal-500',
    required: false,
  },
  {
    id: 'visibility',
    title: 'Visibility & Status',
    description: "Control which investors can see your offering and your offering's status.",
    icon: EyeIcon,
    background: 'bg-fuchsia-500',
    required: false,
  },
])
// Offering Tabs END

// Set Partners START
const partners = ref([])
const selectedPartner = ref({ ...partnerStore.partner })
async function setPartners() {
  partners.value = await partnerStore.getAllPartners()
}
// Set Partners END

// Handle Submit START
const isPublishing = ref(false);
const preventEnterKey = (event) => { if (event.key === 'Enter') event.preventDefault() }
const handleSubmit = () => {
  isPublishing.value = true
  if (mode.value == 'create') createOffering(o.value)
  else updateOffering(o.value)
}
// Handle Submit END

// Create Offering START
async function createOffering(newOffering) {
  // Get Images from newOffering (They will be deleted from newOffering object before being sent to supabase)
  const logoImage = newOffering.logoImage;
  const bannerImage = newOffering.bannerImage;

  // Format Offering Data
  if (userStore.user.profiles_protected.role == 'super_admin' && type.value == 'create') newOffering.partner_id = selectedPartner.value.id;
  else newOffering.partner_id = partnerStore.partner.id;

  // ZOD Validate
  const offering_deep_clone_cleaned = await validateOfferingSchema(newOffering)
  if (!offering_deep_clone_cleaned) { // If the schema is not valid return, the error will be handled in the validateOfferingSchema function
    isPublishing.value = false
    return
  }

  // Create Offering in supabase
  const supabaseOffering = await offeringsStore.createOffering(offering_deep_clone_cleaned);
  if (!supabaseOffering) {
    notify('failure', 'Sorry', 'We were unable to create your offering. If the issue persists, please contact tech support.')
    return
  }

  offering_deep_clone_cleaned.id = supabaseOffering.id

  // Create Offering in TAPI
  const tapi_offering_id = await offeringsStore.createOfferingInTapi(offering_deep_clone_cleaned)
  if (!tapi_offering_id) emailsStore.notifyDevs(id) // If we failed to create the offering in TAPI, notify the team
  offering_deep_clone_cleaned.tapi_offering_id = tapi_offering_id

  // Update Offering in supabase, add tapi_offering_id
  const successful = await offeringsStore.updateOffering(offering_deep_clone_cleaned)
  if (!successful) emailsStore.notifyDevs(id) // If we failed to update the offering in supabase with the new tapi_offering_id, notify the team
  
  // Upload Offering Images to Supabase
  let promises = [];
  promises.push(offeringsStore.uploadImagesToSupabase(supabaseOffering.id, logoImage, bannerImage))
  if (newOffering.content && newOffering.content.length > 0) {
    newOffering.content.forEach((content) => {
      if (content.type == 'image') {
        promises.push(offeringsStore.uploadOfferingImagesToSupabase(supabaseOffering.id, content.name, content.file));
        delete content.file; // Remove image from content object to avoid added excess data to Supabase
      }
    });
  }
  // Wait for all promises to resolve
  await Promise.all(promises);

  isPublishing.value = false

  // Show notification, close popup and refresh offerings
  notify('success', 'Offering Created Successfully', "Don't forget to add any offering or subscription documents that are needed.")

  // Return to offerings page
  router.push({ name: 'offerings' }).then(() => {
    //Make this more precise
    window.location.reload();
  });
};
// Create Offering END

// Update Offering START
const updateOffering = async (updatedOffering) => {
  // Upsert Offering Images to Supabase
  let promises = [];
  promises.push(offeringsStore.uploadImagesToSupabase(updatedOffering.id, updatedOffering.logoImage, updatedOffering.bannerImage))

  if (updatedOffering.content && updatedOffering.content.length > 0) {
    updatedOffering.content.forEach((content) => {
      if (content.type == 'image') {
        promises.push(offeringsStore.uploadOfferingImagesToSupabase(updatedOffering.id, content.name, content.file));
      }
    });
  }
  // Wait for all promises to resolve
  const results = await Promise.all(promises);
  if (results.some(result => !result)) notify('failure', 'Sorry', 'It looks like some of you images did no upload correctly, Please refresh the page and review your offering images. If the issue persists, please contact tech support.');

  // Remove images from content object to avoid added excess data to Supabase
  if (updatedOffering.content && updatedOffering.content.length > 0) {
    updatedOffering.content.forEach((content) => {
      if (content.type == 'image') {
        delete content.file
        delete content.url
      }
    });
  }

  // ZOD Validate
  const offering_deep_clone_cleaned = await validateOfferingSchema(updatedOffering)
  if (!offering_deep_clone_cleaned) { // If the schema is not valid return, the error will be handled in the validateOfferingSchema function
    isPublishing.value = false
    return
  }

  // Update Offering
  const successful = await offeringsStore.updateOffering(offering_deep_clone_cleaned);
  if (!successful) {
    notify('failure', 'Sorry', 'We were unable to update your offering. If the issue persists, please contact tech support.')
    isPublishing.value = false
    return
  }

  // Update Offering in TAPI
  const successfulInTapi = await offeringsStore.updateOfferingInTapi(offering_deep_clone_cleaned);
  if (!successfulInTapi) {
    notify('failure', 'Sorry', 'We were unable to update your offering. If the issue persists, please contact tech support.')
    isPublishing.value = false
    return
  }

  isPublishing.value = false

  // Show notification and close popup
  notify('success', 'Offering Updated Successfully', "Please note that updated images make take a few moments to update on you browser. New images can be seen immediately by clearing the browser's cached images and refreshing the page")

  await setOffering()
}

// Validate Offering Schema
async function validateOfferingSchema(offering_object) {
  // Format Offering Numbers
  offering_object.minimum = parseFloat(offering_object.minimum)
  offering_object.maximum = parseFloat(offering_object.maximum)
  offering_object.unit_price = parseFloat(offering_object.unit_price)
  offering_object.target_amount = parseFloat(offering_object.target_amount)
  // (For older offerings convert the offering_type to a lowercase string)
  offering_object.offering_type = offering_object.offering_type.toLowerCase()
  
  // Validate the schema
  const { data: stripped_offering, error } = OfferingSchema.safeParse(offering_object)
  if (error) {
    await showInvalidFields(error.errors)
    return
  }
  return stripped_offering
}

// User Error Handling START
// ppppp
const safe_offering_form = ref(null)
const safe_select_partner = ref(null)
const safe_name = ref(null)
const safe_select_status = ref(null)
const safe_select_offering_type = ref(null)
const safe_slogan = ref(null)
const safe_market_sector = ref(null)
const safe_score = ref(null)
// Sliders
const safe_slider_overview = ref(null)

const refs = {
  safe_offering_form,
  safe_select_partner,
  safe_name,
  safe_select_status,
  safe_select_offering_type,
  safe_slogan,
  safe_market_sector,
  safe_score,
  safe_slider_overview
}
const getRef = (name) => { return refs[name] }

const showInvalidFields = async (errors) => {
  let first_ref = null
  for (const error of errors) { //This needs to be a for loop so we can use async/await
    const invalid_input_ref = getRef(`safe_${error.path[0]}`);
    const invalid_switch_ref = getRef(`safe_switch_${error.path[0]}`);
    const invalid_select_ref = getRef(`safe_select_${error.path[0]}`);

    if (invalid_input_ref?.value) {
      invalid_input_ref.value?.setErrorMessage(error.message);
      if (!first_ref) first_ref = invalid_input_ref.value;
      break;
    }
    else if (invalid_switch_ref?.value) {
      invalid_switch_ref.value.showError(error.message);
      notify('failure', 'Sorry', error.message);
      if (!first_ref) first_ref = invalid_switch_ref.value;
      break;
    }
    else if (invalid_select_ref?.value) {
      invalid_select_ref.value.showError(error.message);
      notify('failure', 'Sorry', error.message);
      if (!first_ref) first_ref = invalid_select_ref.value;
      break;
    }
    else {
      await handleErrorInSlideovers(error.path[0], error.message);
      notify('failure', 'Sorry', error.message);
      break;
    }
  }
  // Scroll to the last invalid input
  if (first_ref) first_ref.$el.scrollIntoView({ block: "center", inline: "nearest" });
  else window.scrollTo({ top: 0, behavior: 'smooth' });
  return
}

// If the error is in a slideover, show the slideover
const handleErrorInSlideovers = async (path, message) => {
  switch (path) {
    case 'overview':
    case 'description':
    case 'key_values':
      o.value.show_overview = true
      if (path == 'description') {
        await nextTick() //This is needed to ensure the ref is available
        const invalid_slider_ref = getRef(`safe_slider_overview`);
        invalid_slider_ref.value.setErrorMessage(message);
      }
      break;
    case 'scores':
    case 'show_score':
    case 'aum':
    case 'aum_explanation':
    case 'liquidity':
    case 'liquidity_explanation':
    case 'management':
    case 'management_explanation':
    case 'longevity':
    case 'longevity_explanation':
    case 'documents_score':
    case 'documents_explanation':
    case 'financials':
    case 'financials_explanation':
    case 'fee_score':
    case 'fee_explanation':
      o.value.show_scores = true
      break;
    case 'paragraphs':
    case 'content': 
      o.value.show_content = true
      break;
    case 'links':
      o.value.show_links = true
      break;
    case 'checkout':
    case 'gradient_start_color':
    case 'gradient_end_color':
    case 'attestations':
      o.value.show_checkout = true
      break;
    case 'visibility':
    case 'allowed_user_groups':
    case 'visibility':
    case 'status':
      o.value.show_visibility = true
      break;
    case 'settings':
    case 'require_accreditation':
    case 'show_factright_report':
    case 'show_forum':
    case 'use_regcf_protocols':
    case 'allowed_account_types':
      o.value.show_settings = true
      break;
    case 'payment':
    case 'accept_ach':
    case 'accept_cc':
    case 'accept_wire':
    case 'accept_check':
    case 'is_custody_only':
    case 'fedex_instructions':
    case 'mail_instructions':
    case 'bank_instructions':
    case 'account_number_instructions':
    case 'routing_number_instructions':
    case 'reference_instructions':
    case 'address_instructions':
    case 'custody_fedex_instructions':
    case 'custody_mail_instructions':
    case 'custody_bank_instructions':
    case 'custody_account_number_instructions':
    case 'custody_routing_number_instructions':
    case 'custody_reference_instructions':
    case 'custody_address_instructions':
      o.value.show_payment = true
      break;
    case 'tapi':
    case 'start_date':
    case 'end_date':
    case 'minimum':
    case 'maximum':
    case 'unit_price':
    case 'target_amount':
      o.value.show_tapi = true
      break;
    default:
      break;
  }
}
// Update Offering END

// Handle Slideover Viewing START
const handleOptionClick = (id) => {
  if (id == 'settings') o.value.show_settings = !o.value.show_settings
  if (id == 'payment') o.value.show_payment = !o.value.show_payment
  if (id == 'tapi') o.value.show_tapi = !o.value.show_tapi
  if (id == 'overview') o.value.show_overview = !o.value.show_overview
  if (id == 'paragraphs') o.value.show_content = !o.value.show_content
  if (id == 'images') o.value.show_content = !o.value.show_content
  if (id == 'links') o.value.show_links = !o.value.show_links
  if (id == 'documents') o.value.show_documents = !o.value.show_documents
  if (id == 'order') o.value.show_order = !o.value.show_order
  if (id == 'checkout') o.value.show_checkout = !o.value.show_checkout
  if (id == 'scores') o.value.show_scores = !o.value.show_scores
  if (id == 'visibility') o.value.show_visibility = !o.value.show_visibility
  if (id == 'forum') o.value.show_forum_manager = !o.value.show_forum_manager
}
// Handle Slideover Viewing END

// Deep Compare START
function resolveProxies(obj) {
  if (obj && typeof obj === 'object') {
    if (typeof obj.__isProxy === 'boolean') {
      // If it's a proxy object, unwrap it.
      return JSON.parse(JSON.stringify(obj));
    }
    // Recursively process each property of the object.
    return Array.isArray(obj)
      ? obj.map(resolveProxies)
      : Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [key, resolveProxies(value)])
      );
  }
  return obj; // Primitive values are returned as-is.
}

function deepCompare(obj1, obj2, ignoredKeys = []) {
  const cleanObj = (obj, keysToIgnore) => {
    const newObj = resolveProxies({ ...obj }); // Resolve proxies
    keysToIgnore.forEach(key => delete newObj[key]);
    return newObj;
  };

  const obj1Cleaned = cleanObj(obj1, ignoredKeys);
  const obj2Cleaned = cleanObj(obj2, ignoredKeys);

  return JSON.stringify(obj1Cleaned) === JSON.stringify(obj2Cleaned);
}
// Deep Compare END

// Handle Page Exit START
const unsavedChanges = ref(false);
const handleBackButton = () => {
  // TODO make these ignored fields have better default values so they can be compared
  const isEqual = deepCompare(start_offering_state.value, o.value, ['start_date', 'end_date', 'minimum', 'maximum', 'unit_price', 'target_amount', 'content'])
  if (isEqual) { router.push({ name: 'offerings' }); return }
  unsavedChanges.value = true;
}
const confirmedExit = () => {
  unsavedChanges.value = false;
  router.push({ name: 'offerings' });
}
// Handle Page Exit END

// Archive Offering START
async function archiveOffering() {
  const successful = await offeringsStore.archiveOffering(o.value.id);
  if (!successful) {
    notify('failure', 'Sorry', 'We were unable to archive your offering. If the issue persists, please contact tech support.')
    openArchiveOffering.value = false
    return
  }

  notify('success', 'Archive Successful', "Your offering has been successfully archived. Please reach out to Tech Support to restore it")
  openArchiveOffering.value = false

  // Return to offerings page
  router.push({ name: 'offerings' }).then(() => {
    window.location.reload();
  });
};
// Archive Offering END

// Images START
const logoImageChanged = (event) => {
  o.value.logoImage = event.target.files[0]
  if (o.value.logoImage.type != 'image/jpeg' && o.value.logoImage.type != 'image/png') {
    o.value.logoUrl = {}
  } else {
    o.value.logoUrl = URL.createObjectURL(o.value.logoImage)
  }
}

const bannerImageChanged = (event) => {
  o.value.bannerImage = event.target.files[0]
  if (o.value.bannerImage.type != 'image/jpeg' && o.value.bannerImage.type != 'image/png') {
    o.value.bannerUrl = {}
  } else {
    o.value.bannerUrl = URL.createObjectURL(o.value.bannerImage)
  }
}
// Images END

// Notifications START
const notification = ref(null)
const notificationType = ref('success')
const notificationTitle = ref('Offering Created Successfully')
const notificationText = ref("Don't forget to add any offering or subscription documents that are needed.")
const notify = (type, title, text) => {
  notificationType.value = type
  notificationTitle.value = title
  notificationText.value = text
  notification.value.show()
}
// Notifications END


// Set Offering START
const start_offering_state = ref({})
const o = ref({});
const default_offering = {
  offering_type: 'Equity',
  status: 'pending',
  visibility: 'public',
  links: [],
  key_values: [],
  content: [],
  attestations: [],
  allowed_account_types: ['individual', 'joint', 'entity'],
  gradient_start_color: partnerStore.partner.primary_color,
  gradient_end_color: partnerStore.partner.primary_color_light,
  accept_ach: true,
  accept_cc: true,
  accept_wire: true,
  accept_check: true,
  show_score: false,
  use_regcf_protocols: false,
  require_accreditation: false,
  show_factright_report: false,
  show_forum: false,
  is_custody_only: false,
  archived: false,
  score: null,
  // tapi fields
  start_date: '2024-01-01',
  end_date: '2030-12-31',
  minimum: '10',
  maximum: '1000000',
  unit_price: '1',
  target_amount: '1000000',
  // These are for frontend convience only
  show_overview: false,
  show_content: false,
  show_links: false,
  show_documents: false,
  show_order: false,
  show_checkout: false,
  show_scores: false,
  show_visibility: false,
  show_settings: false,
  show_payment: false,
  show_tapi: false,
  show_forum_manager: false
}

const setOffering = async () => {
  o.value = await offeringsStore.getOfferingById(offering_id.value)
  if (!o.value) {
    router.push('/offering-not-found')
    return
  }

  // Set Default Booleans for Slideovers
  o.value.show_overview = false
  o.value.show_content = false
  o.value.show_links = false
  o.value.show_documents = false
  o.value.show_order = false
  o.value.show_checkout = false
  o.value.show_scores = false
  o.value.show_visibility = false
  o.value.show_settings = false
  o.value.show_payment = false
  o.value.show_tapi = false
  o.value.show_forum_manager = false

  // Set Image Urls
  o.value.logoUrl = await offeringsStore.getOfferingFile(o.value.id, o.value.tapi_offering_id, 'logo');
  o.value.bannerUrl = await offeringsStore.getOfferingFile(o.value.id, o.value.tapi_offering_id, 'banner');

  // Set Content Image Urls
  if (o.value.content) {
    o.value.content.forEach(async obj => {
      if (obj.type === 'image' && obj.name) {
        obj.url = await offeringsStore.getOfferingFile(o.value.id, o.value.tapi_offering_id, obj.name);
      }
    });
  }

  // format offering tapi fields
  if (o.value.end_date) o.value.end_date = o.value.end_date.split("T")[0]
  if (o.value.start_date) o.value.start_date = o.value.start_date.split("T")[0]
  if (o.value.minimum) o.value.minimum = o.value.minimum.toString()
  if (o.value.maximum) o.value.maximum = o.value.maximum.toString()
  if (o.value.target_amount) o.value.target_amount = o.value.target_amount.toString()
  if (o.value.unit_price) o.value.unit_price = o.value.unit_price.toString()

  // Set Offering State (To compare against later)
  start_offering_state.value = JSON.parse(JSON.stringify(o.value))
}
// Set Offering END

// Loading START
const openArchiveOffering = ref(false);
const showOfferingPreview = ref(false);
const loading = ref(true);
// Loading END

// Mounted START
const offering_id = ref('')
const mode = ref('create');

const setupEditor = async () => {
  offering_id.value = router.currentRoute.value.params.id

  // Create Offering
  if (!offering_id.value) {
    mode.value = 'create'
    o.value = default_offering
    if (userStore.user.profiles_protected.role == 'super_admin') await setPartners()
    loading.value = false
    return
  }

  // Edit Offering
  mode.value = 'edit'
  await setOffering()

  // Ready
  loading.value = false
}

const copiable_link = ref('')
onMounted(async () => {
  await setupEditor()
  copiable_link.value = `${window.location.origin}/public/${offering_id.value}`
})
// Mounted END

// Watchers
watch(() => route.params.id, () => { setupEditor() })

watch(() => o.value.show_forum, (newVal) => {
  if (newVal) {
    // Logic to handle when show_forum is true
    contentOptions.value.push({
      id: 'forum',
      title: 'Forum Manager',
      description: 'Manage your forum settings and messages for your offering.',
      icon: ChatBubbleBottomCenterTextIcon,
      background: 'bg-cyan-500',
      required: false,
    })
  } else {
    // Logic to handle when show_forum is false
    contentOptions.value = contentOptions.value.filter(item => item.id !== 'forum')
  }
});

watch(() => o.value.show_score, (newVal) => {
  if (newVal) {
    // Logic to handle when show_forum is true
    contentOptions.value.push({
      id: 'scores',
      title: 'Scores & Ratings',
      description: 'Add scores and ratings along with their explanations to your offering.',
      icon: StarIcon,
      background: 'bg-amber-500',
      required: false,
    })
  } else {
    // Logic to handle when show_forum is false
    contentOptions.value = contentOptions.value.filter(item => item.id !== 'scores')
  }
});
</script>