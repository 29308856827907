export const getBadgeClassAlt = (str, use_default = false) => {
  // Accreditation
  if (str == 'Accredited' || str == 'Verified Accredited') return 'badge_green';
  else if (str == 'Action Required') return 'badge_orange';
  else if (str == 'Under Review') return 'badge_blue';
  else if (str == 'Not Accredited') return 'badge_red';
  else if (str == 'Pending') return 'badge_yellow';
  // Genereic statuses
  else if (str == 'approved') return 'badge_green';
  else if (str == 'pending') return 'badge_blue';
  else if (str == 'disapproved') return 'badge_orange';
  // Defaults
  else if (use_default) return 'badge_gray';
  else return str;
}

export const getBadgeClass = (str, use_default = false) => {
  // Accreditation
  if (str == 'Accredited' || str == 'Verified Accredited') return 'badge_green';
  else if (str == 'Action Required') return 'badge_orange';
  else if (str == 'Under Review') return 'badge_blue';
  else if (str == 'Not Accredited') return 'badge_red';
  else if (str == 'Pending') return 'badge_yellow';
  // Transaction statuses
  else if (str == 'settled') return 'badge_green';
  else if (str == 'refunded') return 'badge_purple';
  else if (str == 'refund_pending') return 'badge_pink';
  else if (str == 'funded') return 'badge_blue';
  // Payment statuses
  else if (str == 'settled') return 'badge_green';
  else if (str == 'recieved') return 'badge_blue';
  else if (str == 'failed') return 'badge_red';
  else if (str == 'voided') return 'badge_gray';
  else if (str == 'declined') return 'badge_orange';
  else if (str == 'distributed') return 'badge_green';
  // Genereic statuses
  else if (str == 'approved') return 'badge_green';
  else if (str == 'pending') return 'badge_yellow';
  else if (str == 'disapproved') return 'badge_red';
  // Investment statuses
  else if (str == 'Invested') return 'badge_green';
  else if (str == 'Pending') return 'badge_yellow';
  else if (str == 'Not Invested') return 'badge_red';
  // Investment types
  else if (str == 'equity') return 'badge_blue';
  else if (str == 'fund') return 'badge_green';
  else if (str == 'hybrid') return 'badge_pink';
  else if (str == 'debt') return 'badge_purple';
  // Transaction types
  else if (str == 'buy') return 'badge_green';
  else if (str == 'sell') return 'badge_red';
  else if (str == 'deposit') return 'badge_blue';
  else if (str == 'withdraw') return 'badge_orange';
  // Investment Methods
  else if (str == 'ach') return 'badge_blue';
  else if (str == 'cc' || str == 'creditcard') return 'badge_green';
  else if (str == 'check') return 'badge_teal';
  else if (str == 'wire') return 'badge_purple';
  else if (str == 'custody') return 'badge_indigo';
  else if (str == 'tbd') return 'badge_yellow';
  // Signing statuses
  else if (str == 'NOTSIGNED') return 'badge_yellow';
  else if (str == 'SIGNED') return 'badge_green';
  // Badge Text values
  else if (str == 'badge blue') return 'badge_blue';
  else if (str == 'badge green') return 'badge_green';
  else if (str == 'badge purple') return 'badge_purple';
  else if (str == 'badge orange') return 'badge_orange';
  else if (str == 'badge yellow') return 'badge_yellow';
  else if (str == 'badge red') return 'badge_red';
  else if (str == 'badge pink') return 'badge_pink';
  else if (str == 'badge teal') return 'badge_teal';
  else if (str == 'badge indigo') return 'badge_indigo';
  else if (str == 'badge gray') return 'badge_gray';
  // Account types
  else if (str == 'Individual' || str == 'individual') return 'badge_blue';
  else if (str == 'Joint' || str == 'joint') return 'badge_green';
  else if (str == 'JTWROS' || str == 'jtwros') return 'badge_purple';
  else if (str == 'TIC' || str == 'tic') return 'badge_orange';
  else if (str == 'IRA' || str == 'ira') return 'badge_yellow';
  else if (str == 'ROTH IRA' || str == 'roth ira' || str == 'roth') return 'badge_red';
  else if (str == 'SEP IRA' || str == 'sep ira' || str == 'sep') return 'badge_pink';
  // Units
  else if (str == 'cash') return 'badge_green';
  else if (str == 'dollars') return 'badge_green';
  else if (str == 'shares') return 'badge_blue';
  // Investor Types
  else if (str == 'investor') return 'badge_blue';
  else if (str == 'advisor') return 'badge_purple';
  else if (str == 'partner_admin') return 'badge_green';
  else if (str == 'super_admin') return 'badge_red';
  // Employment Statuses
  else if (str == 'employed') return 'badge_green';
  else if (str == 'unemployed') return 'badge_yellow';
  else if (str == 'retired') return 'badge_blue';
  else if (str == 'student') return 'badge_purple';
  // Defaults
  else if (use_default) return 'badge_gray';
  else return str;
}


// We are going to assume that capitalize class will be applied after
export const getUserFriendlyString = (str, role = null) => {
  if (!str) return ''
  // Investor Types
  else if (str == 'partner_admin' && role == 'super_admin') return 'Partner Admin'
  else if (str == 'partner_admin') return 'Admin'
  else if (str == 'super_admin') return 'Super Admin'
  // Payment Types
  else if (str == 'ach') return 'ACH'
  else if (str == 'creditcard') return 'Credit Card'
  else if (str == 'tbd') return 'TBD'
  // Partner Types
  else if (str == 'dib') return 'DIB'
  else if (str == 'dab') return 'DAB'
  else if (str == 'maas') return 'MAAS'
  // Sigining statuses
  else if (str == 'NOTSIGNED') return 'Not Signed'
  else if (str == 'SIGNED') return 'Signed'
  // Accreditation
  else if (str == 'Verified Accredited') return 'Accredited'
  // Trade statuses
  else if (str == 'refund_pending') return 'Refund Pending'
  // tbas
  else if (str === '/dashboard/settings') return 'Settings Tab'
  else if (str === '/dashboard/investing') return 'Invest Tab'
  else if (str === '/dashboard/documents') return 'Documents Tab'
  else if (str === '/dashboard/education') return 'Education Tab'
  else if (str === '/dashboard/holdings') return 'Holdings Tab'
  else if (str === '/dashboard/payment-methods') return 'Payment Methods Tab'
  else if (str === '/dashboard/accounts') return 'Accounts Tab'
  // Account Types
  else if (str === 'ira') return 'IRA';
  return str;
}

// export const getUserFriendlyAccreditationStatus = (word) => {
//   if (!word) return '';
//     // Accreditation Types
//   else if (word == 'approved') return 'Approved';
//   else if (word == 'pending') return 'Under Review';
//   else if (word == 'disapproved') return 'Action Required';
//   return word;
// }

export const simpleCapitalize = (word) => { 
  if (!word) return '';
  const words = word.split(' ');
  const capitalizedWords = words.map(w => w.charAt(0).toUpperCase() + w.slice(1));
  return capitalizedWords.join(' ');
}

export const capitalizeFirstLetterAlt = (word) => {
  if (!word) return '';
  // Investor Types
  else if (word == 'approved') return 'Approved';
  else if (word == 'pending') return 'Under Review';
  else if (word == 'disapproved') return 'Action Required';
  const words = word.split(' ');
  const capitalizedWords = words.map(w => w.charAt(0).toUpperCase() + w.slice(1));
  return capitalizedWords.join(' ');
}

export const capitalizeFirstLetter = (word) => {
  if (!word) return '';
  // Investor Types
  else if (word == 'partner_admin') return 'Admin';
  else if (word == 'super_admin') return 'Super Admin';
  // Payment Types
  else if (word == 'ach') return 'ACH';
  else if (word == 'creditcard') return 'Credit Card';
  else if (word == 'tbd') return 'TBD';
  // Partner Types
  else if (word == 'dib') return 'DIB';
  else if (word == 'dab') return 'DAB';
  else if (word == 'maas') return 'MAAS';
  // Sigining statuses
  else if (word == 'NOTSIGNED') return 'Not Signed';
  else if (word == 'SIGNED') return 'Signed';
  // Accreditation
  else if (word == 'Verified Accredited') return 'Accredited';
  // Trade statuses
  else if (word == 'refund_pending') return 'Refund Pending'
  // tbas
  else if (word === '/dashboard/settings') return 'Settings Tab';
  else if (word === '/dashboard/investing') return 'Invest Tab';
  else if (word === '/dashboard/documents') return 'Documents Tab';
  else if (word === '/dashboard/education') return 'Education Tab';
  else if (word === '/dashboard/holdings') return 'Holdings Tab';
  else if (word === '/dashboard/payment-methods') return 'Payment Methods Tab';
  else if (word === '/dashboard/accounts') return 'Accounts Tab';
  // Account Types
  else if (word === 'ira') return 'IRA';
  const words = word.split(' ');
  const capitalizedWords = words.map(w => w.charAt(0).toUpperCase() + w.slice(1));
  return capitalizedWords.join(' ');
}

export const truncateText = (t) => {
  if (t && t.length > 100) return t.slice(0, 100) + '...';
  else return t;
}

export const formatDateWithTime = (d) => {
  const date = new Date(d);
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = months[date.getMonth()]; // Get the month
  const day = date.getDate(); // Get the day
  const year = date.getFullYear(); // Get the year
  const hour = date.getHours(); // Get the hour 
  const minute = date.getMinutes(); // Get the minute
  const formattedTime = `${hour % 12 || 12}:${minute.toString().padStart(2, "0")} ${hour < 12 ? "am" : "pm"}`; // Format the time as "hh:mm AM/PM"
  return `${month} ${day}, ${year}, ${formattedTime} `; // Combine all the parts into the desired format
}

export const formatDate = (d) => {
  const date = new Date(d);
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const weekday = weekdays[date.getDay()]; // Get the weekday
  const month = months[date.getMonth()]; // Get the month
  const day = date.getDate(); // Get the day
  const year = date.getFullYear(); // Get the year
  let daySuffix;
  switch (day % 10) {
    case 1:
      daySuffix = "st";
      break;
    case 2:
      daySuffix = "nd";
      break;
    case 3:
      daySuffix = "rd";
      break;
    default:
      daySuffix = "th";
      break;
  }
  if (day >= 11 && day <= 13) {
    daySuffix = "th";
  }
  return `${weekday}, ${month} ${day}${daySuffix}, ${year}`; // Combine all the parts into the desired format
}

export const formatDateNoTimeZone = (d) => {
  const date = new Date(d);
  // No need to adjust for timezone offset since we want to ignore it

  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const weekday = weekdays[date.getUTCDay()]; // Get the weekday in UTC
  const month = months[date.getUTCMonth()]; // Get the month in UTC
  const day = date.getUTCDate(); // Get the day in UTC
  const year = date.getUTCFullYear(); // Get the year in UTC

  let daySuffix;
  switch (day % 10) {
    case 1:
      daySuffix = "st";
      break;
    case 2:
      daySuffix = "nd";
      break;
    case 3:
      daySuffix = "rd";
      break;
    default:
      daySuffix = "th";
      break;
  }
  if (day >= 11 && day <= 13) {
    daySuffix = "th";
  }

  return `${weekday}, ${month} ${day}${daySuffix}, ${year}`; // Combine all the parts into the desired format
}

export const formatDateSimple = (d) => {
  const date = new Date(d);
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get the month and pad with leading zero if necessary
  const day = date.getDate().toString().padStart(2, "0"); // Get the day and pad with leading zero if necessary
  const year = date.getFullYear(); // Get the year  
  return `${month}/${day}/${year}`; // Combine all the parts into the desired format
}

export const formatDateMonthYear = (d) => {
  const date = new Date(d);
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = months[date.getMonth()]; // Get the month
  const day = date.getDate(); // Get the day
  const year = date.getFullYear(); // Get the year
  let daySuffix;
  switch (day % 10) {
    case 1:
      daySuffix = "st";
      break;
    case 2:
      daySuffix = "nd";
      break;
    case 3:
      daySuffix = "rd";
      break;
    default:
      daySuffix = "th";
      break;
  }
  if (day >= 11 && day <= 13) {
    daySuffix = "th";
  }
  return `${month} ${day}${daySuffix}, ${year}`; // Combine all the parts into the desired format
}

export const formatTime = (d) => {
  const date = new Date(d);
  const hour = date.getHours(); // Get the hour 
  const minute = date.getMinutes().toString().padStart(2, "0"); // Get the minute and pad with leading zero if necessary
  const period = hour < 12 ? "am" : "pm"; // Determine if it's AM or PM
  const formattedHour = (hour % 12 || 12).toString(); // Convert hour to 12-hour format
  const formattedTime = `${formattedHour}:${minute}${period}`; // Format the time as "hh:mm AM/PM"
  return formattedTime; // Combine all the parts into the desired format
}

export const formatMoney = (num) => {
  if (!num) return '$0.00';
  if (typeof num === 'string') num = parseInt(num)
  let formattedNum = num.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, });
  return formattedNum;
}

export const formatMoneyString = (str) => {
  if (str === '0') return str;
  //Remove all characters but 1-9 and ,
  str = str.replace(/[^,0-9]/g, '');
  //Remove leading zeros
  str = str.replace(/^0+/, '');
  //Add commas in the right place
  str = str.replace(/,/gi, "");
  str = str.split(/(?=(?:\d{3})+$)/).join(",");
  return str;
};

export const checkDate = (date) => {
  if (date) return date;
  else return null;
};

export const checkTargetAmount = (amount) => {
  if (amount) return amount;
  else return 10000000000000;
};

export const checkMinAmount = (amount) => {
  if (amount) return amount;
  else return 1;
};

export const checkMaxAmount = (amount) => {
  if (amount) return amount;
  else return 10000000000000;
};

export const checkUnitPrice = (amount) => {
  if (amount) return amount;
  else return 1;
};

export const removeCommas = (inputString) => {
  if (!inputString) return null;
  if (typeof inputString === 'number') inputString = inputString.toString();

  // Remove commas
  const sanitizedString = inputString.replace(/,/g, '');

  // Parse as float and format to 2 decimal places
  const parsedNumber = parseFloat(sanitizedString);
  return parsedNumber.toFixed(2);
};

export const formatStartDate = (inputDate) => {
  if (!inputDate) return null;
  if (typeof inputDate === 'string') inputDate = new Date(inputDate);
  var year = inputDate.getFullYear();
  var month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
  var day = inputDate.getDate().toString().padStart(2, '0');
  var formattedDate = `${month}-${day}-${year}`;
  return formattedDate;
};

export const formatEndDate = (inputDate) => {
  if (!inputDate) return null;
  var year = inputDate.getFullYear();
  var month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
  var day = inputDate.getDate().toString().padStart(2, '0');
  var formattedDate = `${month}-${day}-${year}`;
  return formattedDate;
};

export const formatSocialSecurity = (inputString) => {
  if (!inputString) return null;
  if (typeof inputString === 'number') inputString = inputString.toString();
  return inputString.replace(/\D/g, '').replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
}

export const getDocType = (docType) => {
  if (docType == 'image/png') return 'PNG'
  else if (docType == 'image/jpeg') return 'JPG'
  else if (docType == 'application/pdf') return 'PDF'
  else return 'DOC';
}

export const getPlaceholderValue = (type) => {
  switch (type) {
    case 'Equity':
    case 'equity':
      return 'Number of Shares';
    case 'Fund':
    case 'fund':
      return 'Dollar amount';
    default:
      return 'Number of Units';
  }
}

export const getType = (type) => {
  switch (type) {
    case 'Equity':
    case 'equity':
      return 'Shares';
    case 'Fund':
    case 'fund':
      return 'Dollar amount';
    default:
      return 'Units';
  }
}

export const formatIncome = (val) => {
  if (val === '0') return val;
  val = val.replace(/[^,0-9]/g, '');
  val = val.replace(/^0+/, '');
  val = val.replace(/,/gi, "");
  val = val.split(/(?=(?:\d{3})+$)/).join(",");
  return val;
}

export const getUnits = (issueType) => {
  switch (issueType) {
    case 'Equity':
    case 'equity':
      return 'shares';
    case 'Fund':
    case 'fund':
      return 'dollars';
    case 'Debt':
    case 'debt':
      return 'units';
    default:
      return 'units';
  }
}

export const formatFileSize = (size) => {
  if (!size) return
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return `${size.toFixed(1)} ${units[unitIndex]}`;
}

export const getBank = (bank) => {
  if (bank.includes('fidelity')) return 'fidelity'
  if (bank.includes('schwab')) return 'schwab'
  if (bank.includes('bank of america')) return 'boa'
  if (bank.includes('chase')) return 'chase'
  if (bank.includes('wells fargo')) return 'wellsfargo'
  if (bank.includes('citibank')) return 'citi'
  if (bank.includes('goldman sachs')) return 'gs'
  if (bank.includes('truist')) return 'truist'
  if (bank.includes('citizens bank')) return 'cf'
  return 'default';
}


export const getAccountType = (type) => {
  switch (type) {
    case 'Individual':
    case 'individual':
      return 'Individual';
    case 'Joint':
    case 'joint':
      return 'Joint';
    case 'JTWROS':
    case 'jtwros':
      return 'JTWROS';
    case 'TIC':
    case 'tic':
    case 'Joint TIC':
    case 'joint tic':
      return 'Joint TIC';
    case 'IRA':
    case 'ira':
      return 'IRA';
    case 'ROTH IRA':
    case 'roth ira':
      return 'ROTH IRA';
    case 'SEP IRA':
    case 'sep ira':
      return 'SEP IRA';
    default:
      return 'Unknown';
  }
};

const stateAbbreviations = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "FL": "Florida",
  "GA": "Georgia",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PA": "Pennsylvania",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming",
  "NOUS": "Not in the US",
};

export const getStateName = (abbreviation) => {
  return stateAbbreviations[abbreviation?.toUpperCase()] || abbreviation;
}

export const states = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
  "NOUS"
];