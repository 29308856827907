<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-25 dark:bg-neutral-500 dark:bg-opacity-25 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col overflow-y-scroll bg-gray-50 dark:bg-neutral-950 py-6 shadow-xl">

                  <!-- Header -->
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-base font-semibold leading-6 text-gray-900 dark:text-neutral-100">
                        <h3 v-if="props.position.type == 'distribution'" class="text-xl font-bold text-gray-900 sm:text-2xl dark:text-white">Distribution Details</h3>
                        <h3 v-else class="text-xl font-bold text-gray-900 sm:text-2xl dark:text-white">Position Details</h3>
                      </DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="relative rounded-md text-gray-400 hover:text-gray-500 dark:text-neutral-400 dark:hover:text-neutral-300 focus:outline-none" @click="open = false">
                          <span class="absolute -inset-2.5" />
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Header -->

                  <!-- Loading -->
                  <div v-if="loading || !props.position" class="flex flex-grow items-center justify-center h-[70vh]">
                    <div class="w-32 h-32 -mt-20">
                      <SpinnerFlip />
                    </div>
                  </div>

                  <!-- Content -->
                  <div v-else>

                    <!-- Banner Image -->
                    <div class="relative h-40 mt-5">
                      <img class="absolute h-full w-full object-cover" :src="offering_banner_url" alt="" />
                    </div>

                    <!-- Logo Image -->
                    <div class="relative ml-16 -mt-10 z-10">
                      <img class="w-auto z-10 rounded-full h-20 shadow-md ring-1 ring-gray-500 sentry-unmask" :src="offering_logo_url" />
                    </div>

                    <!-- Offering Details -->
                    <div class="relative mt-6 flex-1 flex flex-col px-4 sm:px-6">
                      <div class="flex gap-5 justify-between">
                        <div class="flex flex-col gap-3">
                          <!-- Offering Name -->
                          <div class="font-semibold dark:text-white">{{ props.position.offerings.name }}</div>
                          <!-- Offering Slogan -->
                          <div class="text-sm text-gray-600 dark:text-neutral-300">{{ props.position.offerings.slogan }}</div>
                        </div>

                        <div class="w-40 mt-7">
                          <div class="badge_gray w-fit">{{ props.position.offerings.market_sector }}</div>
                        </div>
                      </div>

                      <div v-if="props.position.offerings.key_values" class="mt-5">
                        <div class="bg-white dark:bg-neutral-900 shadow dark:shadow-none !rounded-xl w-full">
                          <dl class="divide-y divide-gray-100 dark:divide-neutral-800">
                            <div v-for="key_value in props.position.offerings.key_values">

                              <!-- Dynamic Key Values -->
                              <div v-if="key_value.type == 'curated'" class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <!-- Curated Cases -->
                                <dt v-if="key_value.id == 'start-date' && props.position.offerings.offering_type == 'Fund'" class="tertiary_subheader dark:text-white">Inception Date</dt>
                                <dt v-else class="tertiary_subheader dark:text-white">{{ key_value.name }}</dt>

                                <dd v-if="key_value.id == 'security-type'" :class="[getBadgeClass(props.position.offerings.offering_type), 'w-fit mt-1 text sm:col-span-2 sm:mt-0']">{{
                                  capitalizeFirstLetter(props.position.offerings.offering_type) }}</dd>
                                <dd v-else-if="key_value.id == 'minimum-investment'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">{{ formatMoney(props.position.offerings?.minimum) }}</dd>
                                <dd v-else-if="key_value.id == 'maximum-investment'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">{{ formatMoney(props.position.offerings?.maximum) }}</dd>
                                <dd v-else-if="key_value.id == 'raise-goal'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">{{ formatMoney(props.position.offerings?.target_amount) }}</dd>
                                <dd v-else-if="key_value.id == 'unit-price'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">{{ formatMoney(props.position.offerings?.unit_price) }}</dd>
                                <dd v-else-if="key_value.id == 'pending-amount'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">{{ formatMoney(offering_details?.pendingAmount) ?? 'Loading...' }}</dd>
                                <dd v-else-if="key_value.id == 'funded-amount'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">{{ formatMoney(offering_details?.fundedAmount) ?? 'Loading...' }}</dd>
                                <dd v-else-if="key_value.id == 'start-date'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">{{ formatDate(props.position.offerings?.start_date) }}</dd>
                                <dd v-else-if="key_value.id == 'end-date'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">{{ formatDate(props.position.offerings?.end_date) }}</dd>
                                <dd v-else class="w-fit mt-1 text sm:col-span-2 sm:mt-0">Not Available</dd>

                              </div>
                              <!-- Normal Cases -->
                              <div v-else class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="tertiary_subheader dark:text-white">{{ key_value.name }}</dt>
                                <dd :class="[(key_value.type.includes('badge')) ? getBadgeClass(key_value.type) + ' w-fit' : 'mt-1 text sm:col-span-2 sm:mt-0']">{{ key_value.value }}</dd>
                              </div>
                            </div>

                          </dl>
                        </div>
                      </div>

                    </div>

                    <!-- Position Details -->
                    <div class="relative mt-6 flex-1 flex flex-col px-4 sm:px-6">
                      <dl class="space-y-6 pb-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500 dark:text-neutral-400 dark:border-neutral-700">
                        <div class="flex justify-between">
                          <dt v-if="props.position.type =='distribution'">Distribution Type</dt>
                          <dt v-else>Position Type</dt>
                          <dd class="text-gray-900 dark:text-neutral-100 capitalize">{{ positionType }}</dd>
                        </div>

                        <div class="flex justify-between">
                          <dt class="capitalize">First Transaction Created</dt>
                          <dd class="text-gray-900 dark:text-neutral-100">{{ formatDate(props.position.earliest_date) }}</dd>
                        </div>

                        <div class="flex justify-between">
                          <dt class="capitalize">Last Transaction Settled</dt>
                          <dd class="text-gray-900 dark:text-neutral-100">{{ formatDate(props.position.latest_date) }}</dd>
                        </div>
                      </dl>
                      <dl class="space-y-6 border-t border-gray-200 border-dashed pt-6 text-sm font-medium text-gray-500 dark:text-neutral-400 dark:border-neutral-700">
                        <div class="flex justify-between">
                          <dt>Units</dt>
                          <dd class="text-gray-900 dark:text-neutral-100 capitalize">{{ props.position.units }}</dd>
                        </div>

                        <div class="flex justify-between">
                          <dt class="capitalize">{{ `Total ${props.position.units}` }} </dt>
                          <dd class="text-gray-900 dark:text-neutral-100">{{ props.position.total_units }}</dd>
                        </div>

                        <div class="flex justify-between">
                          <dt class="capitalize">{{ `Average Price Per ${props.position.units.slice(0, -1)}` }}</dt>
                          <dd class="text-gray-900 dark:text-neutral-100">{{ formatMoney(props.position.total_value / props.position.total_units) }} USD</dd>
                        </div>

                        <div class="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900 dark:text-neutral-100 dark:border-neutral-700">
                          <dt class="text-base">Total</dt>
                          <dd class="text-base">{{ formatMoney(props.position.total_value) }} USD</dd>
                        </div>
                      </dl>
                    </div>

                  </div>
                  <!-- Content -->

                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// Essentials
import { ref, computed, watch } from 'vue'

// Stores
import { useOfferingsStore } from '@/stores/offerings'
const offeringsStore = useOfferingsStore()

// Components
import SpinnerFlip from '@/components/loading/SpinnerFlip.vue';

// Libraries
import { formatMoney, formatDate, getBadgeClass } from '@/helper/helper'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'

// Model
const open = defineModel()

// Props
const props = defineProps({
  position: { type: Object, required: false, default: null }
})

// Emits
const emit = defineEmits(['article-added'])

// Computed
const positionType = computed(() => {
  if (!props.position) return ''
  if (props.position.type == 'distribution' && props.position.transaction_method == 'distribution' && props.position.units == 'dollars') return 'Cash Distribution'
  if (props.position.type == 'buy') return 'Security'
  return ''
})

// Offering Images START
const offering_logo_url = ref(null)
const offering_banner_url = ref(null)
const setOfferingImages = async () => {
  if (!props.position.offerings || !props.position.offerings.id) return
  // Get Logo
  offering_logo_url.value = await offeringsStore.getOfferingFile(props.position.offering_id, props.position.offerings.tapi_offering_id, 'logo')
  // Get Banner
  offering_banner_url.value = await offeringsStore.getOfferingFile(props.position.offering_id, props.position.offerings.tapi_offering_id, 'banner')
}
// Offering Images END

// Get Offering Details START
const loading_offering_details = ref(false)
const offering_details = ref(null)
async function setOfferingDetails() {
  if (!props.position.offerings.tapi_offering_id) return
  loading_offering_details.value = true
  offering_details.value = await offeringsStore.getOfferingDetails(props.position.offerings.tapi_offering_id)
  loading_offering_details.value = false
}
// Get Offering Details END

// Setup
const loading = ref(true)
const setup = async () => {
  if (!props.position) return

  // Get the position's offering images if they exist
  await setOfferingImages()

  // Get the offering details if they exist
  setOfferingDetails()

  // Ready
  loading.value = false
}

watch(open, (newVal) => {
  if (newVal) setup()
})
</script>
