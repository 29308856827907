import { defineStore } from 'pinia'
import { supabase } from '../lib/supabaseClient'
import { logErrorMessage } from '@/schemas/AdvancedErrorHandler.js'
// import { ref } from 'vue'


export const useDistributionsStore = defineStore('distributions', () => {
  // State (refs)

  // Getters, (computed properties)

  // Actions, (functions)
  async function insertDistribution(distribution) {
    const { data, error } = await supabase.from('distributions').insert(distribution).select('*')
    if (error) { console.log(await logErrorMessage(error)); return }
    return data[0]
  }
  async function getAllDistributionsForPartnerByOffering(partner_id, offering_id) {
    const { data, error } = await supabase.from('distributions').select('*, offerings(*), profiles(*)').eq('partner_id', partner_id).eq('offering_id', offering_id).order('created_at', { ascending: false })
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  }

  // Not Callable Functions

  return {
    insertDistribution,
    getAllDistributionsForPartnerByOffering,
  }
})