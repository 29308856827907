<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <!-- Notification Popup -->
    <NotificationPopup :type="notificationType" :title="notificationTitle" :text="notificationText" ref="notification" />

    <!-- Confirm Popup -->
    <ConfirmAction v-model="showConfirmation" @confirmed="createDistribution" title="Confirm Distribution Intent" message="Are you sure you want to submit this distribution? This will create a record of your distribution to all your investors with a settled transaction. Once a distribution is made, it is not easy to reverse." />

    <!-- Loading -->
    <div v-if="loading" class="flex flex-grow items-center justify-center h-[70vh]">
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Saving Animation -->
    <div v-if="saving" class="flex items-center justify-center h-[70vh]" @click="saving = false; step1 = true">
      <Vue3Lottie v-show="step1" animationLink="https://lottie.host/f397f009-181d-45a1-98e6-a1cc68ee180c/CaInp3A8WJ.json" :height="300" :width="300" :loop="false" :autoplay="false"
        @onAnimationLoaded="droplet_animation_ref.goToAndPlay(0)" @onComplete="checkIfStillUploading" ref="droplet_animation_ref" />
      <Vue3Lottie v-show="!step1" animationLink="https://lottie.host/9bd4593e-b53e-4288-b481-c3d912d7b391/j0ruGdXjGZ.json" :height="300" :width="300" :loop="false" class="mt-20" :autoPlay="false"
        ref="checkmark_animation_ref" @onComplete="saving = false; step1 = false; notify('success', 'Success', 'Your distribution has been created successfully.');" />
    </div>

    <!-- Ready -->
    <div v-if="!loading && !saving">

      <div>
        <!-- Empty State -->
        <div v-if="offerings && offerings.length <= 0">
          <div class="text-center">
            <CubeTransparentIcon class="w-12 h-12 mx-auto text-gray-400 dark:text-neutral-400" />
            <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-neutral-300">No Offerings</h3>
            <p class="mt-1 text-sm text-gray-500 dark:text-neutral-400">It looks like you don't have any Offerings yet.<br /> Please create an Offering to get started.</p>
          </div>
        </div>

        <!-- Has Accounts -->
        <div v-else>
          <!-- Header -->
          <div>
            <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 flex-wrap dark:border-neutral-700">
              <!-- Tab Header -->
              <h3 class="primary_header">Investor Distributions</h3>

              <div class="flex items-center flex-wrap gap-4">
                <!-- Search -->
                <SearchInput v-model="query" />
              </div>
            </div>
          </div>

          <!-- Offering Empty State -->
          <div v-if="!selected_offering" class="mt-20">
            <div class="text-center">
              <CubeTransparentIcon class="w-12 h-12 mx-auto text-gray-400 dark:text-neutral-400" />
              <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-neutral-300">Choose an Offerings to Get Started</h3>
              <p class="mt-1 text-sm text-gray-500 dark:text-neutral-400">Distributions are created on a per offering basis.<br /> Please select an Offering to get started.</p>
            </div>
            <div class="flex justify-center mt-10">
              <ComboMenu :items="offerings" label="Choose an Offering" placeholder="Search Offerings" v-model="selected_offering" type="offering" class="w-96" />
            </div>
          </div>

          <!-- Content -->
          <div v-else>
            <ComboMenu :items="offerings" label="Choose an Offering" v-model="selected_offering" type="offering" placeholder="Search Offerings" class="w-96" />

            <div v-if="loading_investors" class="flex flex-grow items-center justify-center h-[70vh]">
              <div class="w-32 h-32 -mt-10">
                <SpinnerFlip />
              </div>
            </div>

            <div v-else-if="selected_offering && (!investors || investors.length <= 0)">
              <div class="text-center mt-10">
                <UserCircleIcon class="w-12 h-12 mx-auto text-gray-400 dark:text-neutral-400" />
                <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-neutral-300">No Investors</h3>
                <p class="mt-1 text-sm text-gray-500 dark:text-neutral-400">It looks like you don't have any investors for this Offering yet.<br /> Once a investors transaction is moved settled they
                  will be available here.</p>
              </div>
            </div>

            <div v-else-if="selected_offering && investors">
              <div class="flex items-center gap-5 mt-10">
                <!-- Default Distribution Type -->
                <SafeSelect v-model="distribution_default_type" :items="['percent', 'dollar']" label="Default Type" class="w-32" />

                <!-- Default Distribution -->
                <SafeInput v-if="distribution_default_type == 'percent'" @input="minZeroPercent" v-model="default_distribution_percent" type="number" label="Default %" id="default_percent"
                  class="w-32" tooltip="This sets the default value for every row in your distributions table." />
                <SafeInput v-else v-model="default_distribution_dollar" @input="minZeroDollars" type="number" label="Default $" class="w-32" id="default_dollar" />

                <ButtonPrimary text="Apply to All" size="lg" class="self-end" @click="ApplyDefault" :icon="ArrowPathIcon" />
              </div>

              <ButtonPrimary text="Submit Distribution" size="lg" class="mt-10" @click="checkInvestorsAndShowConfirmation" :icon="ReceiptPercentIcon" />

              <!-- Investors Table -->
              <TableSticky :loading="loading_investors" :loading_all="loading_all_investors" :rows="filteredInvestors" v-model="columns" class="-mt-8" export_title="investor_distribution_list" />

              <!-- Old Distributions -->
              <div class="font-semibold text-md text-gray-900 dark:text-white mt-16">Offering's Previous Distributions</div>
              <TableSticky :loading="loading_old_distributions" :loading_all="loading_old_distributions" :rows="old_distributions" v-model="distribution_columns" class="-mt-8"
                export_title="distributions" />

            </div>
          </div>


        </div>
      </div>

    </div>

  </div>
</template>

<script setup>
// Essentials
import { ref, computed, onMounted, watch } from 'vue';

// Components
import SpinnerFlip from '@/components/loading/SpinnerFlip.vue';
import NotificationPopup from '@/components/popups/NotificationPopup.vue'
import TableSticky from '@/components/applicationui/TableSticky.vue'
import ButtonPrimary from '@/components/applicationui/ButtonPrimary.vue';
import SearchInput from '@/components/applicationui/SearchInput.vue';
import ComboMenu from '@/components/applicationui/ComboMenu.vue';
import SafeSelect from '@/components/inputs/safeSelect.vue';
import SafeInput from '@/components/inputs/safeInput.vue';
import ConfirmAction from '@/components/popups/ConfirmAction.vue';

// Libraries
import { Vue3Lottie } from 'vue3-lottie'
import { UserCircleIcon, CubeTransparentIcon, ArrowPathIcon, ReceiptPercentIcon } from '@heroicons/vue/24/outline'


// Stores
import { useTransactionsStore } from '@/stores/transactions';
import { usePartnerStore } from '@/stores/partner';
import { useOfferingsStore } from '@/stores/offerings';
import { useDistributionsStore } from '@/stores/distributions';
// import { useProfilesStore } from '@/stores/profiles';
const transactionsStore = useTransactionsStore();
const offeringsStore = useOfferingsStore();
const partnerStore = usePartnerStore();
const distributionsStore = useDistributionsStore();
// const profilesStore = useProfilesStore();

// Table
const columns = ref([
  { key: 'parties.first_name', key2: 'parties.last_name', label: 'Name', type: 'two-strings', visible: true },
  { key: 'parties.contact_email', label: 'Email', type: 'bold', visible: true },
  { key: 'transactions', label: 'Total Invested', type: 'transaction-total', visible: true },
  { key: 'transactions', label: 'Total Shares', type: 'transaction-shares', visible: true },
  { key: null, label: 'Distribution %', type: 'input', visible: true },
  { key: null, label: 'Distribution $', type: 'input-second', visible: true },
  { key: 'transactions[0].settled_at', label: 'Settled', type: 'date', visible: true }
]);

// Send Distribution START
const showConfirmation = ref(false);
const checkInvestorsAndShowConfirmation = () => {
  if (investors.value.filter(investor => parseFloat(investor.dollar) > 0).length <= 0) {
    notify('failure', "No Distributions", 'It looks like you have not set any distributions for any investors, please set a distribution for at least one investor. Investors with a distribution of $0 will be ignored.');
    return;
  }
  showConfirmation.value = true;
}
const still_uploading = ref(false);
const saving = ref(false);
const step1 = ref(true);
const checkmark_animation_ref = ref(null);
const droplet_animation_ref = ref(null);
const checkIfStillUploading = () => {
  if (!still_uploading.value) {
    step1.value = false;
    checkmark_animation_ref.value.goToAndPlay(0);
    return;
  }
  droplet_animation_ref.value.goToAndPlay(0)
}

const createDistribution = async () => {
  // Set loading values
  showConfirmation.value = false;
  still_uploading.value = true;
  saving.value = true;

  // Create Distribution Record
  const distribution = await distributionsStore.insertDistribution({
    offering_id: selected_offering.value.id,
    partner_id: selected_offering.value.partner_id,
    distribution_default_type: distribution_default_type.value,
    distribution_default_value: distribution_default_type.value === 'percent' ? default_distribution_percent.value : default_distribution_dollar.value,
    total_distribution_value: investors.value.reduce((acc, investor) => acc + parseFloat(investor.dollar), 0),
    total_distributions: investors.value.filter(investor => parseFloat(investor.dollar) > 0).length,
  });
  if (!distribution || !distribution.id) {
    notify('failure', 'Sorry', 'We were unable to create the distribution record, please try again, if the issue persists please contact tech support.');
    saving.value = false;
    still_uploading.value = false;
    return;
  }

  // Create Distribution Transactions
  const promises = investors.value.map(async (investor) => {
    if (investor.dollar > 0) {
      try {
        await transactionsStore.createTransactionInSupabase(null, investor.id, investor.transactions[0]?.offering_id, 'dollars', 1, investor.partner_id, parseFloat(investor.dollar), 'distribution', 'distribution', 'settled', 'distributed', distribution.id, new Date().toISOString());
      } catch (error) {
        notify('failure', 'Distribution Error', `We were unable to create a distribution transaction for the investor ${investor.parties.first_name} ${investor.parties.last_name}`);
      }
    }
  });
  await Promise.all(promises);

  // Get Investors
  await setInvestors();
  // Get Old Distributions
  await setOldDistributions();

  still_uploading.value = false;
}
// Send Distribution END

// Distribution defaults START
const distribution_default_type = ref(null);
const default_distribution_percent = ref(0);
const default_distribution_dollar = ref(0);
const minZeroPercent = () => {
  if (!default_distribution_percent.value || default_distribution_percent.value < 0) default_distribution_percent.value = 0;
}
const minZeroDollars = () => {
  if (!default_distribution_dollar.value || default_distribution_dollar.value < 0) default_distribution_dollar.value = 0;
}
const sumTransactionsDollars = (transactions) => {
  if (!transactions) return 0;
  return transactions.reduce((acc, transaction) => acc + (transaction.amount * transaction.price_per_unit), 0);
}
const ApplyDefault = () => {
  if (distribution_default_type.value == 'percent') {
    investors.value.forEach((investor) => {
      investor.percent = default_distribution_percent.value;
      investor.dollar = (investor.percent * (sumTransactionsDollars(investor.transactions) / 100)).toFixed(2);
    });
  } else {
    investors.value.forEach((investor) => {
      investor.dollar = default_distribution_dollar.value;
      investor.percent = (investor.dollar / (sumTransactionsDollars(investor.transactions) / 100)).toFixed(3);
    });
  }
}
// Distribution defaults END

// Investors START
const investors = ref([]);
const loading_investors = ref(true);
const loading_all_investors = ref(true);
const setInvestors = async () => {
  loading_investors.value = true;
  loading_all_investors.value = true;
  // Get Investors
  const temp_investors = await transactionsStore.getAllInvestedUsersForPartnerByOffering(selected_offering.value.partner_id, selected_offering.value?.id);
  if (!temp_investors) {
    notify('failure', 'Sorry', 'We were unable to investors for this offering, please refresh the page, if the issue persists contact support.');
    loading.value = false;
    return;
  }
  temp_investors.forEach((investor) => {
    investor.percent = 0;
    investor.dollar = 0;
  });
  investors.value = temp_investors;
  loading_investors.value = false;
  loading_all_investors.value = false;
}
// Investors END

// Old Distributions START
const distribution_columns = ref([
  { key: 'profiles.email', label: 'Creator', type: 'default-fill', visible: true },
  { key: 'offerings.name', label: 'Offering', type: 'bold', visible: true },
  { key: 'total_distribution_value', label: 'Total Distributed', type: 'money', visible: true },
  { key: 'total_distributions', label: 'Total Affected', type: 'default-fill', visible: true },
  { key: 'distribution_default_type', label: 'Default Type', type: 'badge', visible: true },
  { key: 'distribution_default_value', label: 'Default', type: 'default-fill', visible: true },
  { key: 'created_at', label: 'Created', type: 'date', visible: true }
]);
const old_distributions = ref([]);
const loading_old_distributions = ref(true);
const setOldDistributions = async () => {
  loading_old_distributions.value = true;
  // Get Old Distributions
  old_distributions.value = await distributionsStore.getAllDistributionsForPartnerByOffering(selected_offering.value.partner_id, selected_offering.value?.id);
  if (!old_distributions) {
    notify('failure', 'Sorry', 'We were unable to get your old distributions, please refresh the page, if the issue persists contact support.');
    loading_old_distributions.value = false;
    return;
  }
  loading_old_distributions.value = false;
}
// Old Distributions END

// Offerings START
const selected_offering = ref(null);
const offerings = ref([]);
async function setOfferings() {
  offerings.value = await offeringsStore.getAllOfferingsForPartnerPaginated(partnerStore.partner.id);
  if (!offerings) {
    notify('failure', 'Sorry', 'We were unable to get your offerings, please refresh the page, if the issue persists contact support.');
    loading.value = false;
    return;
  }
  if (offerings.length <= 0) {
    notify('failure', 'No Offerings Found', "It looks like you don't have any Offerings, please create an offering to get started.");
    loading.value = false;
    return;
  }
  return offerings;
}
// Offerings END

// Search START
const query = ref('');
const filteredInvestors = computed(() => {
  return query.value === '' ? investors.value :
    investors.value.filter((investor) => {
      if (investor.parties.first_name.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor.parties.last_name.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor.parties.contact_email.toLowerCase().includes(query.value.toLowerCase())) return true;
    });
});
// Search END

// Toasts START
const notification = ref(null);
const notificationType = ref('success');
const notificationTitle = ref('Success');
const notificationText = ref('');
const notify = (type, title, text) => {
  notificationType.value = type
  notificationTitle.value = title
  notificationText.value = text
  notification.value.show()
}
// Toasts END

// Mounted START
const loading = ref(true);
onMounted(async () => {
  // Get Investors
  await setOfferings();
  // Ready
  loading.value = false;
});
// Mounted END

// Watchers
watch(selected_offering, () => {
  // Get Investors
  setInvestors();
  // Get Old Distributions
  setOldDistributions();
});
</script>
