<template>
  <div class="relative bg-white rounded-lg shadow-md ring-1 ring-gray-100 h-fit w-full max-w-3xl dark:bg-neutral-800 dark:ring-neutral-700" @mouseenter="animation_ref.play()"
    @mouseleave="animation_ref.stop()">
    <!-- Popups -->
    <NotificationPopup ref="notification" :type="notificationType" :title="notificationTitle" :text="notificationText" />
    <!-- Popups -->

    <!-- Sign Here Popup -->
    <TransitionRoot as="template" :show="showDocusignPopup">
      <Dialog as="div" class="relative z-50" @close="showDocusignPopup = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex items-end justify-center w-full h-full p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative w-5/6 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl dark:bg-neutral-900 h-5/6">
                <div class="w-full h-full">
                  <!-- Loading Docusign -->
                  <div v-if="loadingDocusign" class="w-full h-full flex justify-center items-center">
                    <div class="w-10 h-10">
                      <SpinnerBounce />
                    </div>
                  </div>
                  <!-- Docusign iframe -->
                  <iframe :src="docuSignSrc" class="w-full h-full" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Header -->
    <div class="flex justify-between items-center px-4 py-4 bg-white w-full max-w-3xl rounded-t-lg border-b border-gray-200 border-dashed dark:bg-neutral-800 dark:border-neutral-700">
      <div class="flex items-center gap-x-3">
        <Vue3Lottie animationLink="https://lottie.host/f23cf7be-c67d-476a-b9e1-a6bf07986355/svh9rq5aFC.json" :height="35" :width="35" ref="animation_ref" :loop="false" :autoPlay="true" class="-m-1" />
        <h2 class="max-w-3xl text-base font-semibold text-gray-900 dark:text-neutral-100">Paperwork</h2>
      </div>
      <div class="flex items-center gap-x-1 underline text-operacolor hover:text-operacolorlight text-sm cursor-pointer" @click="emit('show-all')">View all paperwork
        <ArrowTopRightOnSquareIcon class="size-4" />
      </div>
    </div>

    <!-- Loading -->
    <div v-if="loading" class="rounded-b-lg w-full h-48 flex justify-center items-center dark:bg-neutral-900">
      <div class="w-10 h-10">
        <SpinnerBounce />
      </div>
    </div>

    <!-- Content -->
    <ul v-else role="list" class="relative divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900 h-48 rounded-b-lg bg-white"
      :class="[all_documents_signed ? 'overflow-hidden' : 'overflow-y-scroll']">

      <!-- All Docs Signed -->
      <div v-if="all_documents_signed" class="absolute w-full h-48 bg-gray-100 bg-opacity-70 backdrop-blur-sm">
        <div class="rounded-b-lg w-full h-full flex flex-col justify-center items-center">
          <div class="text-gray-900 font-semibold text-md dark:text-neutral-100">All Documents Signed</div>
          <Vue3Lottie animationLink="https://lottie.host/19a7567f-eb2a-4644-99c6-24e7b11039ad/0NePc3nCGG.json" :height="120" :width="120" :loop="false" :autoPlay="true"
            class="-m-1 overflow-visible" />
        </div>
      </div>

      <!-- Docs -->
      <li v-for="doc in documents" :key="doc.id" class="flex sm:gap-x-6 py-3 mx-3 first:pt-3 last:pb-3">
        <div class="flex min-w-0 gap-x-4 items-center">
          <div class="bg-white ring-1 ring-gray-300 p-1 rounded-lg text-xs dark:bg-neutral-800 dark:ring-neutral-700">
            <DocumentCheckIcon v-if="doc.esignStatus == 'SIGNED'" class="text-green-600 w-6 h-6" />
            <DocumentTextIcon v-else class="text-blue-600 w-6 h-6" />
          </div>
          <div class="min-w-0 flex flex-col items-start w-52">
            <p class="font-medium leading-6 text-gray-900 dark:text-neutral-300 truncate">{{ doc.field1 }} Agreement</p>
            <div class="text-xs/5 text-gray-500 dark:text-neutral-400">{{ formatDate(doc.createdDate) }}</div>
          </div>
        </div>
        <div class="shrink-0 flex flex-col items-start justify-center w-40">
          <div class="text-sm/6 text-gray-900 font-medium dark:text-neutral-100">{{ formatMoney(doc.totalAmount) }} USD</div>
          <div class="text-xs/5 text-gray-500 flex items-center gap-x-1 dark:text-neutral-400">
            Trade # <span class="text-gray-900 flex items-center gap-x-0.5 dark:text-neutral-200">
              <span class="truncate max-w-20">{{ doc.orderId }}</span>
              <Copy :text="doc.orderId" class="mb-0.5" />
            </span>
          </div>
        </div>
        <div class="flex-1 flex gap-5 items-center justify-end">
          <div v-if="doc.esignStatus == 'SIGNED'" class="flex w-fit flex-shrink-0 gap-1 items-center">
            <CheckCircleIcon class="w-6 h-6 text-green-600" />
            <span class="secondary_header ml-1">Signing Complete</span>
          </div>
          <div v-else class="flex gap-4">
            <ButtonSecondary text="Resend Email" @click="resendSubscriptionDocuments(doc)" size="lg" :icon="EnvelopeIcon" :loading="doc.sending" />
            <ButtonPrimary text="Sign" @click="signHere(doc)" size="lg" :icon="PencilIcon" :loading="doc.loading" />
          </div>
        </div>
      </li>
    </ul>

  </div>
</template>

<script setup>
// Essentials
import { onMounted, ref, computed } from 'vue'

// Stores
import { useTransactionsStore } from '@/stores/transactions';
const transactionsStore = useTransactionsStore();

// Icons
const animation_ref = ref(null)
import { Vue3Lottie } from 'vue3-lottie'
import { DocumentTextIcon, DocumentCheckIcon, CheckCircleIcon } from '@heroicons/vue/24/solid'
import { ArrowTopRightOnSquareIcon, PencilIcon, EnvelopeIcon } from '@heroicons/vue/24/outline'
import SpinnerBounce from '@/components/loading/SpinnerBounce.vue';

// Libraries
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { formatMoney, formatDate } from '@/helper/helper'

// Components
import Copy from '@/components/applicationui/Copy.vue'
import ButtonPrimary from '@/components/applicationui/ButtonPrimary.vue';
import ButtonSecondary from '@/components/applicationui/ButtonSecondary.vue';
import NotificationPopup from '@/components/popups/NotificationPopup.vue'

// Emits
const emit = defineEmits(['show-all'])

// Props
const props = defineProps({
  account_id: { type: String, required: true },
  tapi_account_id: { type: String, required: true },
})

// Check if all documents are signed
const all_documents_signed = computed(() => {
  return documents.value.every(doc => doc.esignStatus == 'SIGNED');
})

// Sign Document Now START
const loadingDocusign = ref(false);
const showDocusignPopup = ref(false);
async function signHere(trade) {
  trade.loading = true;
  loadingDocusign.value = true;
  showDocusignPopup.value = true;
  await setDocusignUrl(trade, props.account_id)
  createDocusignListener(props.account_id, props.tapi_account_id)
  loadingDocusign.value = false;
  trade.loading = false;
}
const docuSignSrc = ref('')
async function setDocusignUrl(trade, account_id) {
  const data = await transactionsStore.getSubscriptionDocument(account_id, trade.accountId, trade.offeringId, trade.orderId)
  if (!data) { notify('failure', 'Sorry', 'We were unable to get the docusign url for your subscription documents. Please try again, if the issue persists contact support'); return }
  docuSignSrc.value = data.document_details.url
}
const createDocusignListener = (account_id, tapi_account_id) => {
  window.addEventListener("message", (event) => {
    if (!event.origin.includes('norcapsecurities.com')) return
    if (event?.data == 'signing_complete') {
      showDocusignPopup.value = false
      notify('success', 'Success', 'Thank you for signing')
      setDocuments(account_id, tapi_account_id)
    }
  });
}
// Sign Document Now END

// Resend Subscription Documents START
async function resendSubscriptionDocuments(trade) {
  trade.sending = true;
  const successful = await transactionsStore.emailSubscriptionDocument(trade.orderId, props.account_id, trade.accountId, trade.offeringId)
  if (!successful) { notify('failure', 'Sorry', 'We were unable to resend your subscription documents. Please try again later, if the issue persists contact support'); return }
  notify('success', 'Success', 'Your subscription documents were resent, please check you inbox')
  trade.sending = false;
}
// Resend Subscription Documents END

// Get Trade Documents START
const documents = ref([])
async function setDocuments(account_id, tapi_account_id) {
  // Get trades
  const data = await transactionsStore.getTradesFromTapi(account_id, tapi_account_id);

  // Check if data is available
  if (!data) { notify('failure', 'Sorry', 'We were unable to retrieve your subscription documents'); return }
  if (!data?.partyDetails) return []

  // Group data by orderId
  const groupedData = data.partyDetails.reduce((acc, trade) => {
    if (!acc[trade.orderId]) {
      acc[trade.orderId] = [];
    }
    acc[trade.orderId].push(trade);
    return acc;
  }, {});

  // Process grouped data (This is to weed out duplicates because tapi is weird)
  const processedData = Object.values(groupedData).map(group => {
    const allNotSigned = group.every(trade => trade.esignStatus === 'NOTSIGNED');
    if (allNotSigned) {
      return group.filter(trade => trade.esignStatus === 'NOTSIGNED').reduce((max, trade) => trade.id > max.id ? trade : max);
    } else {
      return group.reduce((max, trade) => trade.id > max.id ? trade : max);
    }
  });

  // Add loading key value pair to each trade in partyDetails
  processedData.forEach(trade => trade.loading = false);

  // Sort trades by created date
  processedData.sort((a, b) => { return new Date(b.createdDate) - new Date(a.createdDate) });

  // Return ordered trades
  documents.value = processedData;
}
// Get Trade Documents END

// Notification START
const notification = ref(null);
const notificationType = ref('success');
const notificationTitle = ref('Success');
const notificationText = ref('');
const notify = (type, title, text) => {
  notificationType.value = type
  notificationTitle.value = title
  notificationText.value = text
  notification.value.show()
}
// Notification END

// Mounted START
const loading = ref(true);
onMounted(async () => {
  // Set documents
  await setDocuments(props.account_id, props.tapi_account_id);

  // Ready
  loading.value = false;
});
</script>